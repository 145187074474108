import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/flomesh.io/flomesh.io/src/layouts/Fixed/DocMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <p>{`Starting Pipy Repo is pretty straight-forward, default port is `}<inlineCode parentName="p">{`6060`}</inlineCode>{` and as introduced in `}<a parentName="p" {...{
        "href": "/operating/cli/"
      }}>{`Pipy CLI`}</a>{` you can configure listening port to your specified needs by passing the `}<inlineCode parentName="p">{`--admin-port`}</inlineCode>{` command line argument.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ pipy
2021-10-09 10:48:37 [info] [codebase] Starting codebase service...
2021-10-09 10:48:37 [info] [listener] Listening on port 6060 at ::
`}</code></pre>
    <p>{`Open `}<a parentName="p" {...{
        "href": "http://localhost:6060"
      }}>{`http://localhost:6060`}</a>{` in your browser to access it。`}</p>
    <p><img parentName="p" {...{
        "src": "/img/docs/repo/repo-home.png",
        "alt": null
      }}></img></p>
    <p>{`Built-in documentation:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/docs/repo/pipy-doc.png",
        "alt": null
      }}></img></p>
    <p>{`You can also add `}<strong parentName="p">{`New Codebase`}</strong>{` via `}<inlineCode parentName="p">{`New Codebase`}</inlineCode>{` link. By default, a `}<inlineCode parentName="p">{`main.js`}</inlineCode>{` script will be added as the main entry script:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/docs/repo/new-codebase.png",
        "alt": null
      }}></img></p>
    <p><inlineCode parentName="p">{`main.js`}</inlineCode>{` default contents as below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`pipy()
`}</code></pre>
    <p>{`Let's add something to it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`pipy()
  .listen(8080)
  .serveHTTP(
    new Message('Hi!')
  )
`}</code></pre>
    <h3 {...{
      "id": "codebase-editor"
    }}>{`Codebase Editor`}</h3>
    <p>{`If you have gone through `}<a parentName="p" {...{
        "href": "/operating/admin-gui/"
      }}>{`Admin Console`}</a>{` document, then this interface won't be new to you and if you haven't yet done that, it is recommend to go through that introduction first, as this section will be only discuss the difference specific to Pipy Repo.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/docs/repo/codebase-editor.png",
        "alt": null
      }}></img></p>
    <ol>
      <li parentName="ol">{`File list: Slightly different from the development console. If the file is followed by an asterisk `}<inlineCode parentName="li">{`*`}</inlineCode>{`,  `}<strong parentName="li">{`indicates that the file has been modified but not committed.`}</strong></li>
      <li parentName="ol">{`Resets a file that is modified but not committed to its previously committed version.`}</li>
      <li parentName="ol">{`Submits changes (will update `}<em parentName="li">{`codebase`}</em>{` version number).`}</li>
      <li parentName="ol">{`Sets the current codebase as `}<strong parentName="li">{`Base`}</strong>{`.`}</li>
      <li parentName="ol">{`View/Create a `}<strong parentName="li">{`Derivative`}</strong>{`s from the current Codebase.`}</li>
    </ol>
    <h3 {...{
      "id": "debugging-scripts"
    }}>{`Debugging Scripts`}</h3>
    <p>{`Refer to `}<a parentName="p" {...{
        "href": "/operating/admin-gui/"
      }}>{`Admin Console`}</a>{` document for its usage, click top right play (▶︎) button to run script.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/docs/repo/codebase-running.png",
        "alt": null
      }}></img></p>
    <p>{`try accessing `}<inlineCode parentName="p">{`localhost:8080`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl localhost:8080
Hi!
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      